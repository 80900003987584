.search_input {
  z-index: 10;

  &:before {
    content: "Naam:";
    font-family: var(--source-sans-pro);

    font-size: 13px;
    position: absolute;
    margin-top: 32px;
    margin-left: 16px;
  }

  input {
    background-color: var(--white) !important;
    margin: 23px auto 0px;
    height: 40px !important;
    border-radius: 6px !important;
    font-size: 13px;
    border: 2px solid var(--light-grey) !important;
    line-height: 15px;
    padding-left: 60px;
  }
}

.search_error {
  position: absolute;
  top: 50%;
  right: 90px;
  width: initial !important;
  font-size: 10px;
  transform: translate(0, -35%);
  z-index: 11;
}

.boxie {
  background: var(--white) !important;
  border-radius: 6px !important;
  border: 2px solid var(--light-grey) !important;
  // min-height: 150px;
}

.input_100 input.medium,
select.medium,
textarea.medium {
  width: 100% !important;
}

.btn_search {
  position: absolute;
  right: 20px;
  margin-top: 20px;
  border-radius: 3px !important;
  z-index: 20;
}
.btn_advanced {
  position: absolute;
  right: 110px;
  margin-top: 20px;
  border-radius: 3px !important;
  z-index: 20;
  cursor: pointer;
}

.searchcard {
  padding: 50px 15px 15px;
  position: relative;
  margin-bottom: 20px !important;
  min-width: fit-content !important;
  @media screen and (max-width: 992px) {
    width: 100%;
  }

  @media screen and (max-width: 760px) {
    width: 600px;
  }

  @media screen and (max-width: 575px) {
    width: 100%;
  }
}

.button_container {
  cursor: pointer;
  position: absolute;
  top: 15px;
  right: 15px;

  i {
    font-size: 10px;
  }
}

.no_result {
  font-weight: 600;
  font-size: 15px;
  text-align: center;
}

.pagination_btn {
  background: var(--near-black);
  border-radius: 6px;
}
