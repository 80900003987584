.top-navigation {
    &__dossier {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-left: 0rem;
        padding-right: 1rem;
    }
    
    &__item {
        & > .btn-link {
          height: 2.25rem;
          font-weight: 700;
          text-transform: none !important;
        }
    }
}

.dropdown-item {
    text-transform: none !important;
    letter-spacing: normal !important;
}